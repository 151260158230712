import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useState } from 'react';
import ScanMetadata from '../../common/types/ScanMetadata';
import powered_by_logo_white from '../public/images/powered-by-logo-white.svg';
import attention_icon from '../public/images/attention-icon.svg';
import improvement_icon from '../public/images/improvement-icon.svg';
import ok_icon from '../public/images/ok-icon.svg';
import great_icon from '../public/images/great-icon.svg';
import stellar_icon from '../public/images/stellar-icon.svg';
import scrollAnimation from '../public/images/scroll-animation.gif';
import twitter_icon from '../public/images/twitter-icon.svg';
import card_green_icon from '../public/images/card-green-icon.svg';
import card_yellow_icon from '../public/images/card-yellow-icon.svg';
import card_red_icon from '../public/images/card-red-icon.svg';
import recommendations_icon from '../public/images/recommendations-icon.svg';
import bulb_icon from '../public/images/bulb-icon.svg';
import wrench_icon from '../public/images/wrench-icon.svg';
import ScoreIndicatorComponent from '../../common/components/ScoreIndicatorComponent';
import SubscoreIndicatorComponent from '../../common/components/SubscoreIndicatorComponent';
import ComplianceHealthScore, { PPAType, AppType, CBAType, PrivacyTerms } from '../../common/types/ComplianceHealthScore';

enum CardType {
    GREEN,
    YELLOW,
    RED
};

type DetectedCardProps = {
    cardType: CardType;
    title: string;
    children: any;
  };
  
const DetectedCardComponent = ({ cardType, title, children }: DetectedCardProps): JSX.Element => {
    return (
        <div className="flex flex-col items-center space-y-5 p-10 border-gray-100 border-2 rounded-xl detected-card-component">
            <img src={
                {
                    [CardType.GREEN]: card_green_icon,
                    [CardType.YELLOW]: card_yellow_icon,
                    [CardType.RED]: card_red_icon
                }[cardType]
            } />
            <Typography variant="h4">{title}</Typography>
            {children}
        </div>
    );
};

const LAW_NAMES = {
    [PrivacyTerms.GDPRTerm]: 'GDPR (General Data Protection Regulation)',
    [PrivacyTerms.CCPATerm]: 'CCPA (California Consumer Privacy Act)',
    [PrivacyTerms.CPRATerm]: 'CPRA (California Privacy Rights Act)',
    [PrivacyTerms.PIPEDATerm]: 'PIPEDA (Personal Information Protection and Electronic Documents Act)',
    [PrivacyTerms.LGPDTerm]: 'LGPD (General Personal Data Protection Law)',
    [PrivacyTerms.CPATerm]: 'CPA (Colorado Privacy Act)',
    [PrivacyTerms.VCDPATerm]: 'VCDPA (Virginia Consumer Data Protection Act)',
    [PrivacyTerms.CTDPATerm]: 'CTDPA (Connecticut Data Privacy Act)',
    [PrivacyTerms.UCPATerm]: 'UCPA (Utah Consumer Privacy Act)',
    [PrivacyTerms.POPIATerm]: 'POPIA (Protection of Personal Information Act)'
}

const LAW_LOCATIONS = {
    [PrivacyTerms.GDPRTerm]: 'United Kingdom and European Union',
    [PrivacyTerms.CCPATerm]: 'California, United States',
    [PrivacyTerms.CPRATerm]: 'California, United States',
    [PrivacyTerms.PIPEDATerm]: 'Canada',
    [PrivacyTerms.LGPDTerm]: 'Brazil',
    [PrivacyTerms.CPATerm]: 'Colorado, United States',
    [PrivacyTerms.VCDPATerm]: 'Virginia, United States',
    [PrivacyTerms.CTDPATerm]: 'Connecticut, United States',
    [PrivacyTerms.UCPATerm]: 'Utah, United States',
    [PrivacyTerms.POPIATerm]: 'South Africa'
}

type DetectedLawsProps = {
    detected: boolean;
    laws: PrivacyTerms[];
};

const DetectedLawsComponent = ({ detected, laws }: DetectedLawsProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="detected-laws-component">
            <div
                className={`flex justify-between items-center h-16 bg-gray-50 border-l-4 cursor-pointer ${detected ? '' : 'un'}detected-laws-header`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <Typography
                    variant='h4'
                    className="p-2 px-5"
                >
                    {laws.length} privacy laws {detected ? '' : 'un'}detected
                    <Tooltip
                        title={detected ? 'Great job, we detected the following privacy laws in your Privacy Policy.' : 'We did not find the following relevant laws in your privacy policy.  Please check with your compliance expert whether your policy is compliant.'}
                        className="ml-2"  
                    >
                        <InfoOutlinedIcon />
                    </Tooltip>
                </Typography>
                <div className="p-2 px-5">
                    {isOpen ? <ArrowDropDownIcon/> : <ArrowLeftIcon/>} 
                </div>
            </div>
            {
                isOpen &&
                <div>
                    {
                        laws.map(l =>
                        <div key={l} className="flex flex-col p-1 px-4 border-b border-l border-r border-gray-300">
                            <Typography variant='body1'>{LAW_NAMES[l]}</Typography>
                            <Typography variant='body2' className="text-gray-400">{LAW_LOCATIONS[l]}</Typography>
                        </div>
                        )
                    }
                </div>                
            }
        </div>
    );
};

type RecommendationCardProps = {
    cardType: CardType;
    title: string;
    backlink: string;
    children: any;
  };
  
const RecommendationCardComponent = ({ cardType, title, backlink, children }: RecommendationCardProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={
                `w-full bg-gray-50 border-l-4 ` +
                {
                    [CardType.GREEN]: 'recommendation-card-green',
                    [CardType.YELLOW]: 'recommendation-card-yellow',
                    [CardType.RED]: 'recommendation-card-red'
                }[cardType]
            }
        >
            <div
                className="flex justify-between items-center h-16 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="p-2 px-7">
                    <Typography variant='h4' className="collapse-heading">{title}</Typography>
                    {
                        !isOpen &&
                        <span className="text-gray-400">
                            {
                                {
                                    [CardType.GREEN]: '',
                                    [CardType.YELLOW]: 'Requires attention',
                                    [CardType.RED]: 'Requires immediate attention'
                                }[cardType]
                            }
                        </span>
                    }
                </div>
                <div className="p-2 px-5">
                    {isOpen ? <ArrowDropDownIcon/> : <ArrowLeftIcon/>} 
                </div>
            </div>
            {
                isOpen &&
                <div className="flex items-start p-2 px-7 space-x-4">
                    <img src={
                        {
                            [CardType.GREEN]: card_green_icon,
                            [CardType.YELLOW]: card_yellow_icon,
                            [CardType.RED]: card_red_icon
                        }[cardType]
                    } />
                    <div className="flex flex-col w-full space-y-4">
                        {children}
                        <div className="flex w-full justify-end start-building-row">
                            <a target="_blank" href={backlink || 'https://app.enzuzo.com/onboarding'}>
                                <div className="p-2 px-5 btn-large text-white rounded sm:whitespace-nowrap w-min"><Typography variant='body1'>Start Building</Typography></div>
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

type ScanResultsProps = {
    complianceHealthScore: ComplianceHealthScore;
    scanMetadata: ScanMetadata;
};

const ScanResultsComponent = ({complianceHealthScore, scanMetadata}: ScanResultsProps) : JSX.Element => {
    const score = complianceHealthScore.score;
    const privacyPolicyScore = complianceHealthScore.privacy_policy.score / 4;
    const termsOfServiceScore = complianceHealthScore.terms_of_service.score / 3;
    const cookieConsentScore = complianceHealthScore.cookie_consent_score / 3;
    const hasPrivacyPolicy = complianceHealthScore.privacy_policy.type !== PPAType.NOT_FOUND;
    const hasTermsOfService = complianceHealthScore.terms_of_service.type !== AppType.NOT_FOUND;
    const hasCookieBanner = complianceHealthScore.cookie_banner.type !== CBAType.NOT_FOUND_OR_VENDOR_NOT_RECOGNIZED;
    const requiresCookieBanner = complianceHealthScore.cookie_banner.is_required;
    const privacyTerms = Array.from(new Set(complianceHealthScore.privacy_policy.terms));
    const privacyTermsExpected = Array.from(new Set(complianceHealthScore.privacy_policy.expected_terms));
    const privacyTermsMissing = privacyTermsExpected.filter(x => !privacyTerms.includes(x));

    return (
        <div className="flex scan-results-component">
            <div className="p-10 py-5 flex flex-col space-y-5 scan-score-container text-white h-full">
                <div className="flex flex-col space-y-2 justify-self-center">
                    <div className="w-full">
                        <a href="https://www.enzuzo.com/privacy-compliance-scanner">
                            <ArrowBackIcon/>
                        </a>
                    </div>
                    <Typography variant="h3" className="text-center pt-0 sidebarTitle">Enzuzo Compliance Score</Typography>
                    <div className="w-3/5 pt-3 flex self-center justify-center">
                        <ScoreIndicatorComponent endvalue={score} />
                    </div>
                    <Typography variant="body1" className="weight-600 self-center">{scanMetadata?.url}</Typography>
                </div>
                <div className="space-y-1 self-center">
                    <div>
                        <Typography variant="body1">Privacy Policy</Typography>
                        <SubscoreIndicatorComponent endvalue={privacyPolicyScore} />
                    </div>
                    <div>
                        <Typography variant="body1">Terms of Service</Typography>
                        <SubscoreIndicatorComponent endvalue={termsOfServiceScore} />
                    </div>
                    <div>
                        <Typography variant="body1">Cookie Consent</Typography>
                        <SubscoreIndicatorComponent endvalue={cookieConsentScore} />
                    </div>
                </div>
                <div className="flex flex-col self-center space-y-2">
                    <Typography variant="h4" className="scoreTitle text-center">Need help boosting your score?</Typography>
                    <Typography variant="body1" className="text-center">Enzuzo has everything you need to manage data privacy compliance for your business.</Typography>
                </div>
                <a target="_blank" href="https://app.enzuzo.com/onboarding" className="self-center">
                    <div className="p-3 flex text-center w-48 border border-white rounded-full">
                        <Typography variant="h4" className="text-center w-full">Get Started</Typography>
                    </div>
                </a>
                <img src={powered_by_logo_white} width="177" className="self-center"></img>
            </div>
            <div className="space-y-2 w-full scan-results-container">
                <div className="share-banner flex w-full p-3 px-10 space-x-5 items-center justify-between">
                    <Typography variant="body1">
                        {
                            score <= 60
                            ? <span>Give your compliance score a boost with Enzuzo!</span>
                            : <span><span className="weight-600">Great score?</span> Show your friends how you handle privacy</span>
                        }
                    </Typography>
                    {
                        score <= 60
                        ?
                            <a target="_blank" href="https://app.enzuzo.com/onboarding">
                                <div className="btn-large p-2 px-5 text-white rounded flex justify-center items-center space-x-2 w-32 h-10">
                                    <Typography variant="body2">Start Building</Typography>
                                </div>
                            </a>
                        :
                            <a target="_blank" href={buildTwitterShareUrl(score)}>
                                <div className="btn-large p-2 px-5 text-white rounded flex justify-center items-center space-x-2 w-32 h-10">
                                    <Typography variant="body2">Twitter</Typography>
                                    <img src={twitter_icon} />
                                </div>
                            </a>
                    }
                </div>
                <div className="p-20 py-10 flex flex-col items-center space-y-10">
                    <div className="flex flex-col items-center space-y-5">
                        <Typography variant="h1" className="text-center">
                            Your website compliance
                            {
                                score <= 40 ? <span> needs <span className="text-red-500">Attention </span><img src={attention_icon} className="inline" /></span>
                                : score <= 60 ? <span> needs <span className="text-red-500">Improvement </span><img src={improvement_icon} className="inline" /></span>
                                : score <= 70 ? <span> looks <span className="text-yellow-500">Ok </span><img src={ok_icon} className="inline" /></span>
                                : score <= 80 ? <span> looks <span className="color-tertiary">Great </span><img src={great_icon} className="inline" /></span>
                                : <span> looks <span className="color-tertiary">Stellar </span><img src={stellar_icon} className="inline" /></span>
                            }
                        </Typography>
                        <Typography variant="body1" className="text-center">
                            {
                                score <= 40 ? <span>Hmm, your website may be non-compliant with privacy law! We&apos;re here to help fix this. See our compliance recommendations below and create a free Enzuzo account to get started.</span>
                                : score <= 60 ? <span>There&apos;s still room for improvement! Let&apos;s see how we can improve that score. Take a look at your compliance results below, and in minutes you can take action by creating an Enzuzo account.</span>
                                : score <= 70 ? <span>Not too shabby. Let&apos;s see how we can improve that score. Take a look at your compliance results below, and in minutes you can take action by creating an Enzuzo account.</span>
                                : score <= 80 ? <span>Great work! You&apos;ve taken big steps toward protecting your customers&apos; privacy. Scroll down to see our recommendations on how to take your website compliance to the next level.</span>
                                : <span>Nice work! Your website compliance is out of this world. Don&apos;t forget to share your score on social media to show your community that your business takes data privacy seriously.</span>
                            }
                        </Typography>
                    </div> 
                    <img src={scrollAnimation} width={120} />
                    <div className="flex flex-col items-center">
                        <Typography variant="h2" className="policies-heading">Your Legal Policies</Typography>
                        <Typography variant="body1" className="text-center">We scanned your website for legal policies, and here&apos;s what we found:</Typography>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 card-container">
                        <DetectedCardComponent cardType={hasPrivacyPolicy ? CardType.GREEN : CardType.RED} title={'Privacy Policy'}>
                            <Typography variant="body1" className="text-center">
                                {
                                    hasPrivacyPolicy
                                    ?
                                        <span><span className="weight-600">Looking good!</span> You&apos;re providing your users with an up-to-date Privacy Policy.</span>
                                    :
                                        <span>We couldn&apos;t find a Privacy Policy on your website. Learn more about creating one <a href="https://www.enzuzo.com/privacy-policy-generator" target="_blank" className="underline text-blue-400">here</a>.</span>
                                }
                            </Typography>
                        </DetectedCardComponent>
                        <DetectedCardComponent cardType={hasTermsOfService ? CardType.GREEN : CardType.RED} title={'Terms of Service'}>
                            <Typography variant="body1" className="text-center">
                                {
                                    hasTermsOfService
                                    ?
                                        <span><span className="weight-600">Looking good!</span> You&apos;re providing your users with an up-to-date Terms of Service.</span>
                                    :
                                        <span>We couldn&apos;t find a Terms of Service on your website. Learn more about creating one <a href="https://www.enzuzo.com/terms-of-service-generator" target="_blank" className="underline text-blue-400">here</a>.</span>
                                }
                            </Typography>
                        </DetectedCardComponent>
                    </div>
                    {
                        !!privacyTerms.length &&
                        <DetectedLawsComponent
                            detected={true}
                            laws={privacyTerms}
                        />
                    }
                    {
                        !!privacyTermsMissing.length &&
                        <DetectedLawsComponent
                            detected={false}
                            laws={privacyTermsMissing}
                        />
                    }
                    <div className="flex flex-col items-center">
                        <Typography variant="h2" className="policies-heading">Your Cookie Consent</Typography>
                        <Typography variant="body1" className="text-center">We scanned your website for a Cookie Consent solution, and here&apos;s what we found:</Typography>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 card-container">
                        <DetectedCardComponent cardType={hasCookieBanner ? CardType.GREEN : (requiresCookieBanner ? CardType.RED : CardType.YELLOW)} title={'Cookie Banner'}>
                            <Typography variant="body1" className="text-center">
                                {
                                    hasCookieBanner
                                    ?
                                        <span><span className="weight-600">Looking good!</span> Looks like you have a Cookie Consent banner displayed on your website.</span>
                                    :
                                        (
                                            requiresCookieBanner
                                            ?
                                                <span><span className="weight-600">Warning:</span> We were unable to locate a Cookie Banner on your website. <a href="https://www.enzuzo.com/cookie-banner-generator" target="_blank" className="underline text-blue-400">Learn more</a> about cookie consent.</span>
                                            :
                                                <span><span className="weight-600">Warning:</span> We were unable to locate a Cookie Banner on your website. To find out more about creating a Cookie Banner, <a href="https://www.enzuzo.com/cookie-banner-generator" target="_blank" className="underline text-blue-400">read our guide</a>.</span>
                                        )
                                }
                            </Typography>
                        </DetectedCardComponent>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="flex space-x-2">
                            <Typography variant="h2" className="policies-heading">Recommendations</Typography>
                            <img src={recommendations_icon} />
                        </div>
                        <Typography variant="body1" className="text-center">Here are some privacy compliance tips to help improve your score:</Typography>
                    </div>
                    <div className="w-full space-y-3">
                    <RecommendationCardComponent
                        cardType={hasPrivacyPolicy ? CardType.GREEN : CardType.RED}
                        title={'Privacy Policy'}
                        backlink={'https://app.enzuzo.com/privacy'}
                    >
                        {
                            hasPrivacyPolicy
                            ?
                                <div className="space-y-5">
                                    <Typography variant='body1'>You&apos;ve published a privacy policy and the date it went into effect.</Typography>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={bulb_icon} className="inline pr-2" /><Typography variant='body1'>Privacy Tip</Typography></div>
                                        <Typography variant='body1'>It can be hard to keep track of all the privacy regulations, locations and content that apply to your business. That&apos;s why we recommend approaching your privacy policy with the strictest applicable regulations.</Typography>
                                    </div>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Take Action</Typography></div>
                                        <Typography variant='body1'>In minutes, create a privacy policy for your website using our easy generator. Your policy stays up to date and is formatted in a readable way.</Typography>
                                    </div>
                                </div>
                            :
                                <div className="space-y-4">
                                    <Typography variant='body1'>We were unable to find a privacy policy on your website.</Typography>
                                    <Typography variant='body1'>Privacy policies exist to inform your users about how, why, and when their data will be collected and processed. They are <span className="weight-600">required by law</span> in most countries if you&apos;re collecting data that can be used to identify a person.</Typography>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Fix This Issue</Typography></div>
                                        <Typography variant='body1'>In minutes, create a privacy policy for your website using our easy generator. Your policy stays up to date and is formatted in a readable way.</Typography>
                                    </div>
                                </div>
                        }
                    </RecommendationCardComponent>
                    <RecommendationCardComponent
                        cardType={hasTermsOfService ? CardType.GREEN : CardType.RED}
                        title={'Terms of Service'}
                        backlink={'https://app.enzuzo.com/tos'}
                    >
                        {
                            hasTermsOfService
                            ?
                                <div className="space-y-5">
                                    <Typography variant='body1'>You&apos;ve published a terms of service and the date it went into effect.</Typography>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={bulb_icon} className="inline pr-2" /><Typography variant='body1'>Privacy Tip</Typography></div>
                                        <Typography variant='body1'>When it comes to terms of service compliance, staying up-to-date can be a challenge. Legal complexities surrounding language and concepts can make things even harder, not to mention knowing when it&apos;s time to update your policy. Good news, we&apos;re here to help.</Typography>
                                    </div>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Take Action</Typography></div>
                                        <Typography variant='body1'>In minutes, create a terms of service for your website using our easy generator. Your policy stays up to date and is formatted in a readable way.</Typography>
                                    </div>
                                </div>
                            :
                                <div className="space-y-4">
                                    <Typography variant='body1'>We were unable to find a terms of service on your website.</Typography>
                                    <Typography variant='body1'>Terms of service are rules and guidelines that govern the use of your website or application. They outline the terms, restrictions and responsibilities of both users and service providers. While not required by law in most countries, having clear and transparent terms is important for establishing trust between you and your users.</Typography>
                                    <div>
                                        <div className="weight-600"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Fix This Issue</Typography></div>
                                        <Typography variant='body1'>In minutes, create a terms of service for your website using our easy generator. Your policy stays up to date and is formatted in a readable way.</Typography>
                                    </div>
                                </div>
                        }
                    </RecommendationCardComponent>
                    <RecommendationCardComponent
                        cardType={hasCookieBanner ? CardType.GREEN : (requiresCookieBanner ? CardType.RED : CardType.YELLOW)}
                        title={'Cookie Banner'}
                        backlink={'https://app.enzuzo.com/cookiebanner'}
                    >
                        {
                            hasCookieBanner
                            ?
                                <div className="space-y-5">
                                    <Typography variant='body1'>You&apos;re displaying a cookie banner on your website.</Typography>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={bulb_icon} className="inline pr-2" /><Typography variant='body1'>Privacy Tip</Typography></div>
                                        <Typography variant='body1'>To comply with regulations like GDPR, you must get users&apos; consent before storing or accessing any of their data on your site. That&apos;s why you should display a cookie banner on the user&apos;s first visit to your site, implement a cookie policy and ask for consent.</Typography>
                                    </div>
                                    <div>
                                        <div className="weight-600 flex items-center"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Take Action</Typography></div>
                                        <Typography variant='body1'>Create a customized cookie banner for your website in minutes using Enzuzo&apos;s easy generator.</Typography>
                                    </div>
                                </div>
                            :
                                (
                                    requiresCookieBanner
                                    ?
                                        <div className="space-y-5">
                                            <Typography variant='body1'>Be careful! It seems you are not displaying a cookie banner on your website.</Typography>
                                            <Typography variant='body1'>Based on your users&apos; targeted locations, in order to comply with the EU/UK (GDPR) and California (CPRA), we recommend adding a cookie consent banner to your site.</Typography>
                                            <div>
                                                <div className="weight-600 flex items-center"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Fix This Issue</Typography></div>
                                                <Typography variant='body1'>You can use Enzuzo&apos;s cookie consent generator to create one for your website today.</Typography>
                                            </div>
                                        </div>
                                    :
                                        <div className="space-y-4">
                                            <Typography variant='body1'>Be careful! It seems you are not displaying a cookie banner on your website.</Typography>
                                            <Typography variant='body1'>Based on your users&apos; targeted regions, a cookie banner is not required for privacy compliance.</Typography>
                                            <div>
                                                <div className="weight-600 flex items-center"><img src={bulb_icon} className="inline pr-2" /><Typography variant='body1'>Privacy Tip</Typography></div>
                                                <Typography variant='body1'>Although it is not legally required, giving customers control over their browsing experience on your website is always a good idea.</Typography>
                                            </div>
                                            <div>
                                                <div className="weight-600 flex items-center"><img src={wrench_icon} className="inline pr-2" /><Typography variant='body1'>Take Action</Typography></div>
                                                <Typography variant='body1'>You can use Enzuzo&apos;s cookie consent generator to create one for your website today.</Typography>
                                            </div>
                                        </div>
                                )
                        }
                    </RecommendationCardComponent>
                    </div>
                </div>
                <div className="flex w-full p-1 px-6 items-center justify-between border-t">
                    <Typography variant="body2">© 2023 Enzuzo. All rights reserved.</Typography>
                    <div className="flex space-x-2">
                        <a target="_blank" href="https://www.enzuzo.com/privacy-policy">
                            <Typography variant="body2">Privacy Policy</Typography>
                        </a>
                        <Typography variant="body2">•</Typography>
                        <a target="_blank" href="https://www.enzuzo.com/terms-of-service">
                            <Typography variant="body2">Terms of Service</Typography>
                        </a>
                        <Typography variant="body2">•</Typography>
                        <a target="_blank" href="https://www.enzuzo.com/dsar-form">
                            <Typography variant="body2">Make a Data Request</Typography>
                        </a>
                        <Typography variant="body2">•</Typography>
                        <a target="_blank" href="https://www.enzuzo.com/#manage_cookies">
                            <Typography variant="body2">Manage Cookies</Typography>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

function buildTwitterShareUrl(score: number) {
    const twitterShareContent = buildTwitterShareContent(score)
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterShareContent)}`;

    return shareUrl;
}

function buildTwitterShareContent(score: number) {
    if (score >= 70) {
        return `Check out my website’s privacy compliance score!\n\nWant to see how you measure up? 💪 Scan your website with Enzuzo — the best privacy compliance software for growing businesses.\nhttps://enzuzo.com/privacy-compliance-scanner/share/${score}?utm_source=scanner&utm_medium=backlink&utm_campaign=twitter @enzuzoprivacy`
    }

    return `Get a free privacy compliance score for your website! Scan your URL today with Enzuzo - the best privacy compliance software for growing businesses.\nhttps://enzuzo.com/privacy-compliance-scanner/share/?utm_source=scanner&utm_medium=backlink&utm_campaign=twitter @enzuzoprivacy`
}

export default ScanResultsComponent;

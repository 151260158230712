import React, { useState, useRef, useEffect } from 'react';
import ScanProgressComponent from './components/ScanProgressComponent';
import { Button, TextField, Typography, IconButton, InputAdornment } from '@material-ui/core';
import ScanMetadata from '../common/types/ScanMetadata';
import ScanResultsComponent from './components/ScanResultsComponent';
import { DateTime } from 'luxon';
import logo from './public/images/logo.svg';
import powered_by_logo from './public/images/powered-by-logo.svg';
import * as ArgesWebsocketClient from './api/websocket/ArgesWebsocketClient';
import WebsocketMessageContract from '../common/types/WebsocketMessageContract';
import WebsocketEvents from '../common/WebsocketEvents';
import { APIResponseStatus } from '../common/api-response-status';
import { Alert } from '@material-ui/lab';
import ComplianceHealthScore from '../common/types/ComplianceHealthScore';
import { Region } from '../common/types/Region';
import PageState from '../common/types/PageState';
import { useTranslation } from 'react-i18next';
import ScanProgres from '../common/types/ScanProgres';

const Home = (): JSX.Element => {
  const [scan, setScan] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [ranScanFromUrlParams, setRanScanFromUrlParams] = useState(false);
  const [pageState, setPageState] = useState<PageState>(PageState.HOME);
  const [metadata, setMetadata] = useState<ScanMetadata>({ url: null, email: null, score: 0, scanTime: 0 });
  const [progress, setProgress] = useState<ScanProgres>({ progress_percentage: 0, urls_scanned_count: 0, urls_total: 0 });
  //  const [milestone, setMilestone] = useState('Beginning Scan...');
  const [milestone, setMilestone] = useState('');
  const [errorInfo, setErrorInfo] = useState('');
  const [apiResponseStatus, setAPIResponseStatus] = useState<APIResponseStatus>(null);
  const [complianceHealthScore, setComplianceHealthScore] = useState<ComplianceHealthScore>(null);
  const { t } = useTranslation();

  const onMessageReceived = (message: WebsocketMessageContract<any>) => {
    const { data, status, event } = message;
    setAPIResponseStatus(status);

    console.debug(message);
    
      switch(event) {
        case WebsocketEvents.SCAN_RESULT:
          setScan(data);
          break;
        case WebsocketEvents.SCAN_PROGRESS:
          setProgress(data);
          break;
        case WebsocketEvents.SCAN_COMPLETE:
          // setMetadata({ ...metadata, score: data.complianceHealthScore.score, progress: 100 });
          setComplianceHealthScore(data.complianceHealthScore);
          setPageState(PageState.COMPLETED);
          setMilestone('Your Scan is Complete!');
          break;
        case WebsocketEvents.SCAN_ERROR:
          setPageState(PageState.ERROR);
          break;
        case WebsocketEvents.SCAN_STATE:
          /*
          if (data.pos) {
            setMetadata((m) => ({ ...m, progress: data.pos }));
          }
          setMilestone(data.name);
          */
          break;
      }
  }

  const runScan = async (url: string, regions: Region[] = []) => {
    setPageState(PageState.SCANNING);
    setMetadata({ url: null, email: null, score: 0, scanTime: 0 });
    setProgress({ progress_percentage: 0, urls_scanned_count: 0, urls_total: 0 });


    try {
      ArgesWebsocketClient.disconnect();
      ArgesWebsocketClient.addMessageReceivedListener(onMessageReceived);
      // await ArgesWebsocketClient.connect('wss://4sc194g5ad.execute-api.us-east-1.amazonaws.com/qa');
      // await ArgesWebsocketClient.connect('wss://ffz5u6nk4e.execute-api.us-east-1.amazonaws.com/eng');
      await ArgesWebsocketClient.connect(' wss://jini4f9pc7.execute-api.us-east-1.amazonaws.com/prod');      
      // await ArgesWebsocketClient.connect('ws://localhost:3011');
      ArgesWebsocketClient.sendStartScan(url, regions);
    } catch (error) {
      setErrorInfo('Sorry, there was an issue scanning that URL! Please refresh the page and try again');
      setPageState(PageState.ERROR);
    }
  };

  const runScanFromUrlParams = async (inputUrl?: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const url = typeof inputUrl === 'undefined' ? urlParams.get('url') : inputUrl;
    if (url) {
      runScan(url, urlParams.getAll('regions').map(x => x as Region).filter(x => Object.values(Region).includes(x)));
      setMetadata({ ...metadata, url: url })
    }
  }

  if (!ranScanFromUrlParams) {
    setRanScanFromUrlParams(true);
    runScanFromUrlParams();
  }

  switch (pageState) {
    case PageState.HOME:
      return null;
    case PageState.SCANNING:
      return (
        <ScanProgressComponent scan={scan} milestone={milestone} progress={progress}/>
      );
    case PageState.COMPLETED:
      return (
        <div className="h-screen">
          <ScanResultsComponent complianceHealthScore={complianceHealthScore} scanMetadata={metadata}/>
        </div>
      );
    case PageState.ERROR:
      return (
        <div className="h-screen grid place-items-center scan-progress-component">
          <div className="flex flex-col h-full w-11/12 sm:w-8/12 md:w-1/2 lg:w-6/12 space-y-10 items-center">
            <div className="flex-grow"></div>
            <div className="flex flex-col items-center space-y-4 color-primary">
              <Typography variant="h1" className="weight-400">We&apos;re Sorry</Typography>
              <Typography variant="h3" className="weight-400">There was an error while scanning your page.</Typography>
            </div>
            <TextField 
              id="text" 
              variant="outlined"
              className="errorScanInput" 
              placeholder="www.examplesite.com" 
              onChange={event => setMetadata({ ...metadata, url: event.target.value })} 
              onKeyPress={(ev) => ev.key === 'Enter' && runScanFromUrlParams(metadata.url) }
              fullWidth 
              value={metadata?.url}/>
            <Button variant="contained" color="primary" className="homeGoBtn w-96" onClick={() => runScanFromUrlParams(metadata.url)}>Retry Scan</Button>
            <div className="flex flex-row space-x-2 justify-center text-gray-500">
              <Typography variant="h4" className="weight-400 text-center">Please check to make sure you&apos;ve entered the correct URL. If you&apos;re certain that you have, it&apos;s possible that we are experiencing technical difficulties. Please try again later.</Typography>
            </div>
            <div className="flex flex-grow">
              <img src={powered_by_logo} className="mx-auto align-bottom object-none object-bottom mb-24 content-end"/>
            </div>
          </div>
        </div>
      );
    default:
      setPageState(PageState.HOME);
      return (
        <h1>We&apos;re undergoing some maintenance, please try again later</h1>
      );
  }
};

export default Home;

import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import PropTypes from 'prop-types';
import ProgressProvider from './ProgressProvider';


type ScoreIndicatorProps = {
  endvalue: number;
};

const ScoreIndicatorComponent = ({ endvalue }: ScoreIndicatorProps): JSX.Element => {
  const gradientId = 'score-indicator';
  return (
    <ProgressProvider valueStart={0} valueEnd={endvalue}>
      {(value: number) => <>
        <svg width="0">
          <defs>
            <linearGradient id={gradientId} x1="0.5" y1="1" x2="1" y2="0.5">
              <stop offset="0" stopColor="#FEF6B2"></stop>
              <stop offset="1" stopColor="#18A39C"></stop>
            </linearGradient>
          </defs>
        </svg>
        <CircularProgressbar className="score-progress-bar" styles={buildStyles({ pathColor: `url(#${gradientId})`, trailColor: '#9CA3AF', textColor: 'white', textSize: '25px', strokeLinecap: 'butt'})} value={value} text={`${value}%`} />
      </>}
    </ProgressProvider>
  );
};

ScoreIndicatorComponent.propTypes = {
  endvalue: PropTypes.number.isRequired,
};

export default ScoreIndicatorComponent;

import React from 'react';
import PropTypes from 'prop-types';
import ProgressProvider from './ProgressProvider';
import { LinearProgress } from '@material-ui/core';
import circle_warning_white from '/article17_site/public/images/circle-warning-white.svg';
import triangle_warning_white from '/article17_site/public/images/triangle-warning-white.svg';
import check_white from '/article17_site/public/images/check-white.svg';


type SubscoreIndicatorProps = {
  endvalue: number;
};

const SubscoreIndicatorComponent = ({ endvalue }: SubscoreIndicatorProps): JSX.Element => {
  return (
    <ProgressProvider valueStart={0} valueEnd={endvalue}>
      {(value: number) =>
        <div className="flex items-center w-56 h-4 justify-between">
          <div className="w-52">
            <LinearProgress
              variant="determinate"
              value={value * 10}
              className={`bg-gray-400 ${value < 5 ? 'subscore-progress-bar-bad' : (value < 7 ? 'subscore-progress-bar-normal' : 'subscore-progress-bar-good')}`}
            />
          </div>
          <img src={value < 5 ? circle_warning_white : (value < 7 ? triangle_warning_white : check_white)} width={10}/>
        </div>
      }
    </ProgressProvider>
  );
};

SubscoreIndicatorComponent.propTypes = {
  endvalue: PropTypes.number.isRequired,
};

export default SubscoreIndicatorComponent;

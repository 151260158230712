import { Categories } from "../../../common/types/Categories";
import { Region } from "../../../common/types/Region";
import StartScanRequestContract from "../../../common/types/StartScanRequestContract";
import WebsocketMessageContract from "../../../common/types/WebsocketMessageContract";

export let webSocket: WebSocket;
export let url: string;

const messageReceivedListener: ((message: WebsocketMessageContract<any>) => void) [] = [];
// The buffer has the structure: messageId -> WebsocketMessageContract[]
const messagesReceivedBufferMap: Map<string, WebsocketMessageContract<string>[]> = new Map();

/**
 * Websocket connect
 * @param _url
 */
export async function connect(_url: string): Promise<void> {
    url = _url;

    return new Promise((resolve, reject) => {
        if (!webSocket || webSocket.readyState !== WebSocket.OPEN) {
            try {
                webSocket = new WebSocket(_url);

                webSocket.addEventListener('open', () => {
                    resolve();
                });

                webSocket.addEventListener('error', (error) => {
                    reject(error);
                });

                webSocket.addEventListener('open', _onSocketOpen);
                webSocket.addEventListener('close', _onSocketClose);
                webSocket.addEventListener('message', event => _onSocketMessage(JSON.parse(event.data) as WebsocketMessageContract<any>));
            } catch(error) {
                reject(error);
            }

        } else if (webSocket.readyState === WebSocket.OPEN) {
            resolve();
        } else {
            reject();
        }
    })
}

export async function disconnect(): Promise<void> {
    webSocket?.close();
}

export function addMessageReceivedListener(listener: ((message: WebsocketMessageContract<any>) => void)) {
    // Prevent listeners to be registered more than once
    if (!messageReceivedListener.includes(listener)) {
        messageReceivedListener.push(listener);
    }
}

export function sendStartScan(url: string, regions: Region[]) {
    const startScanRequest: StartScanRequestContract = {
        action: "scan_url",
        url,
        regions,
        scannerMode: 'express'
    }
    webSocket.send(JSON.stringify(startScanRequest));
}

export function getReadyState(): number {
    return webSocket?.readyState;
}

/**
 * Websocket open handler
 */
const _onSocketOpen = () => {
    console.debug('Arges Websocket opened');
}

/**
 * Websocket close handler
 */
const _onSocketClose = () => {
    console.debug('Arges Websocket closed');
}

/**
 * Websocket message handler
 * @param message
 */
const _onSocketMessage = (message: WebsocketMessageContract<any>): void => {
    console.debug('Arges Websocket message');

    // If message is partitioned we have to store it in a buffer until it's completion and then relese it
    if(!message.metadata?.message?.isPartitioned) {
        // Invoke messageReceivedListener's realeasing the message
        messageReceivedListener.forEach(listener => listener(message));
    } else {
        // Buffer the partitioned message
        const {messageId} = message.metadata.message;

        // Initialize the buffer if required
        messagesReceivedBufferMap.set(messageId, messagesReceivedBufferMap.get(messageId) || []);

        // Store the partition in the buffer
        messagesReceivedBufferMap.get(messageId).push(message);

        console.debug(`New chunk added to buffer, messageId: ${messageId}`);
        console.trace(messagesReceivedBufferMap.get(messageId));

        // If buffer completed, rebuilt and release message
        if (_isBufferedMessageComplete(messageId)) {
            const messageRebuilt = _rebuildBufferedMessage(messageId);
            messageReceivedListener.forEach(listener => listener(messageRebuilt));
        }
    }
}

/**
 * Check if buffered message is complete
 * @param messageId
 * @returns
 */
const _isBufferedMessageComplete = (messageId: string): boolean => {
    const buffer = messagesReceivedBufferMap.get(messageId);
    return (buffer && buffer.length > 0 && buffer[0].metadata.message.partitionTotal === buffer.length);
}

/**
 * Rebuild buffered message
 * @param messageId
 * @returns
 */
const _rebuildBufferedMessage = (messageId: string): WebsocketMessageContract<any> => {
    const buffer = messagesReceivedBufferMap.get(messageId);

    // If buffer has all partitions then rebuild and release original message
    if (_isBufferedMessageComplete(messageId)) {
        const sortedBuffer = buffer.sort((a, b) => a.metadata.message.partitionNumber - b.metadata.message.partitionNumber);
        const messageString = sortedBuffer.map(partition => partition.data).reduce((prev, curr) => `${prev}${curr}`, '');
        const message = JSON.parse(messageString) as WebsocketMessageContract<any>;

        return message;
    }

    return null;
}

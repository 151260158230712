import React, { Component } from 'react';
import Home from './Home';
import ThemeWrapper from './ThemeWrapper';
import { hot } from "react-hot-loader/root";
import './i18n';

class App extends Component {
  render() {
    return (
      <>
        <ThemeWrapper>
          <Home />
        </ThemeWrapper>
      </>
    );
  }
}

export default hot(App);

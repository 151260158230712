const lightPalette = {
  enzuzoTheme: {
    palette: {
      primary: {
        light: '#F3F3F3',
        main: '#1C245C',
        dark: '#E65100',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F3E5F5',
        main: '#82A3C9',
        dark: '#7B1FA2',
        contrastText: '#fff',
      },
    },
  },
  enzuzoBlueTheme: {
    palette: {
      primary: {
        offwhite: '#f2f7f7',
        light: '#18a39c',
        main: '#115564',
        dark: '#0C3A44',
        contrastText: '#fff',
      },
      secondary: {
        light: '#BDBDBD',
        main: '#828282',
        dark: '#4F4F4F',
        contrastText: '#fff',
      },
      neutral: {
        sand: '#F9F7F0',
        dark: '#090924',
      },
      error: {
        light: '#E2776A',
        main: '#EE2D25',
        dark: '#DC4B35',
      },
      warning: {
        light: '#FFF5D5',
        main: '#F2C94C',
        dark: '#ECB448',
      },
      info: {
        light: '#CEDDEF',
        main: '#8EB1DA',
        dark: '#5893D4',
      },
      success: {
        light: '#9FFFA9',
        main: '#94EC92',
        dark: '#219653',
      },
      grey: {
        silverBlue: '#F5FAFF',
        offWhite: '#FAFAFA',
        extraLight: '#F3F2F2',
        light: '#E0E0E0',
        mid: '#BDBDBD',
        med: '#828282',
        dark: '#4F4F4F',
        extraDark: '#090924',
        text: '#090924',
      },
    },
  },
  enzuzoGreyTheme: {
    palette: {
      primary: {
        light: '#F3F3F3',
        main: '#82A3C9',
        dark: '#E65100',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F3E5F5',
        main: '#273157',
        dark: '#7B1FA2',
        contrastText: '#fff',
      },
    },
  },
  orangeTheme: {
    palette: {
      primary: {
        light: '#FFF3E0',
        main: '#EF6C00',
        dark: '#E65100',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F3E5F5',
        main: '#9C27B0',
        dark: '#7B1FA2',
        contrastText: '#fff',
      },
    },
  },
  purpleRedTheme: {
    palette: {
      primary: {
        light: '#EDE7F6',
        main: '#673AB7',
        dark: '#512DA8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FCE4EC',
        main: '#EC407A',
        dark: '#C2185B',
        contrastText: '#fff',
      },
    },
  },
  greenOrangeTheme: {
    palette: {
      primary: {
        light: '#F1F8E9',
        main: '#689F38',
        dark: '#33691E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FFF8E1',
        main: '#FF8F00',
        dark: '#E65100',
        contrastText: '#fff',
      },
    },
  },
  magentaTheme: {
    palette: {
      primary: {
        light: '#FCE4EC',
        main: '#EC407A',
        dark: '#D81B60',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FFF8E1',
        main: '#FFA000',
        dark: '#FF6F00',
        contrastText: '#fff',
      },
    },
  },
  purpleTheme: {
    palette: {
      primary: {
        light: '#EDE7F6',
        main: '#AB47BC',
        dark: '#8E24AA',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#006064',
        contrastText: '#fff',
      },
    },
  },
  blueTheme: {
    palette: {
      primary: {
        light: '#E8EAF6',
        main: '#3F51B5',
        dark: '#283593',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E1F5FE',
        main: '#03A9F4',
        dark: '#0277BD',
        contrastText: '#fff',
      },
    },
  },
  cyanTheme: {
    palette: {
      primary: {
        light: '#E0F7FA',
        main: '#009688',
        dark: '#00695C',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F1F8E9',
        main: '#689F38',
        dark: '#33691E',
        contrastText: '#fff',
      },
    },
  },
  redTheme: {
    palette: {
      primary: {
        light: '#FFEBEE',
        main: '#EF5350',
        dark: '#E53935',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ECEFF1',
        main: '#607D8B',
        dark: '#455A64',
        contrastText: '#fff',
      },
    },
  },
  skyBlueTheme: {
    palette: {
      primary: {
        light: '#E3F2FD',
        main: '#2196F3',
        dark: '#1565C0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F2F1',
        main: '#00BFA5',
        dark: '#00796B',
        contrastText: '#fff',
      },
    },
  },
  greyTheme: {
    palette: {
      primary: {
        light: '#ECEFF1',
        main: '#607D8B',
        dark: '#455A64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0E0E0',
        main: '#757575',
        dark: '#424242',
        contrastText: '#fff',
      },
    },
  },
  yellowBlueTheme: {
    palette: {
      primary: {
        light: '#E1F5FE',
        main: '#039BE5',
        dark: '#01579B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FFF3E0',
        main: '#FF9800',
        dark: '#E65100',
        contrastText: '#fff',
      },
    },
  },
  pinkBlueTheme: {
    palette: {
      primary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#006064',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FCE4EC',
        main: '#F06292',
        dark: '#AD1457',
        contrastText: '#fff',
      },
    },
  },
  yellowCyanTheme: {
    palette: {
      primary: {
        light: '#FFF3E0',
        main: '#F9A825',
        dark: '#F57F17',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#006064',
        contrastText: '#fff',
      },
    },
  },
  blueCyanTheme: {
    palette: {
      primary: {
        light: '#E1F5FE',
        main: '#039BE5',
        dark: '#01579B',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#00838F',
        contrastText: '#fff',
      },
    },
  },
  greenPurpleTheme: {
    palette: {
      primary: {
        light: '#EDE7F6',
        main: '#7C4DFF',
        dark: '#311B92',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E8F5E9',
        main: '#00C853',
        dark: '#1B5E20',
        contrastText: '#fff',
      },
    },
  },
  pinkGreenTheme: {
    palette: {
      primary: {
        light: '#DCEDC8',
        main: '#689F38',
        dark: '#33691E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FCE4EC',
        main: '#F06292',
        dark: '#AD1457',
        contrastText: '#fff',
      },
    },
  },
  goldTheme: {
    palette: {
      primary: {
        light: '#FFF9C4',
        main: '#FF9100',
        dark: '#FF6D00',
        contrastText: '#fff',
      },
      secondary: {
        light: '#EFEBE9',
        main: '#8D6E63',
        dark: '#4E342E',
        contrastText: '#fff',
      },
    },
  }
};

export default lightPalette;

import React from 'react';
import PropTypes from 'prop-types';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import enzuzoTheme from '../common/theme/enzuzoTheme';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins] });

const uiDefaults = {
  theme: 'enzuzoBlueTheme',
  direction: 'ltr',
  type: 'light',
};

class ThemeWrapper extends React.Component {
    state: {
        theme: Theme,
        pageLoaded: boolean
    }

  constructor(props: any) {
    super(props);

    this.state = {
      pageLoaded: true,
      theme: createTheme(enzuzoTheme(uiDefaults.theme, uiDefaults.type, uiDefaults.direction) as any),
    };
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      pageLoaded,
      theme,
    } = this.state;

    return (
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
          <div className="">
            {children}
          </div>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}

export default ThemeWrapper;

import lightPalette from './lightPalette';
import darkPalette from './darkPalette';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const themePalette = (color: string, mode: string) => {
  if (mode === 'dark') {
    return (darkPalette as any)[color];
  }
  return (lightPalette as any)[color];
};

export default themePalette;

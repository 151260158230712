import { useEffect, useState } from 'react';

type ProgressProviderProps = {
  valueStart: number;
  valueEnd: number;
  children: any;
};

const ProgressProvider = ({ valueStart, valueEnd, children }: ProgressProviderProps): JSX.Element => {
  const [value, setValue] = useState(valueStart);

  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

export default ProgressProvider;

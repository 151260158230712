import { LinearProgress, Typography } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import ScanProgres from '../../common/types/ScanProgres';
import powered_by_logo from '../public/images/powered-by-logo.svg';

type ScanProgressProps = {
    scan: {};
    progress: ScanProgres;
    milestone: string;
}; 

const ScanProgressComponent = ({scan, milestone, progress}: ScanProgressProps) : JSX.Element => {
    const [categories, setCategories] = useState<string[]>([]);
    const [currentCategorie, setCurrentCategorie] = useState('');
    const { progress_percentage: progressPercentage } = progress;
    
    useEffect(() => {
        // Catgories currently scanned
        let scanCategories = Object.keys(scan);
        
        // Detect last category
        for(let scanCategory of scanCategories) {
            if(!categories.find(_category => _category === scanCategory)){
                setCurrentCategorie(scanCategory);
            }
        }

        setCategories(scanCategories);
    }, [scan]);
    
    return (
        <div className="h-screen grid place-items-center scan-progress-component">
            <div className="flex flex-col h-full w-11/12 sm:w-8/12 md:w-1/2 lg:w-6/12">
                <div className="flex-grow"></div>
                <div className="flex flex-col items-center space-y-4 color-primary">
                    <Typography variant="h1" className="weight-400">Just a moment</Typography>
                    <Typography variant="h3" className="weight-400">we&apos;re analyzing your website!</Typography>
                </div>
                <div className="w-auto py-10">
                    <LinearProgress
                        variant="determinate"
                        value={progressPercentage + 100}
                        className="scan-progress-bar"
                    />
                    <Typography variant="body2" className="relative text-right color-primary scan-progress-bar-percentage">{progressPercentage}%</Typography>
                </div>
                <div className="flex flex-row space-x-2 justify-center text-gray-500">
                <Typography variant="h4" className="weight-400 text-center overflow-ellipsis overflow-hidden whitespace-nowrap w-screen">{milestone}</Typography>
                </div>
                <div className="flex flex-grow">
                    <img src={powered_by_logo} className="mx-auto align-bottom object-none object-bottom mb-24 content-end"/>
                </div>
            </div>
        </div>
    );
}

export default ScanProgressComponent;
import themePalette from '../palette/themePaletteMode';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { ThemeOptions } from '@material-ui/core';

const breakpoints = createBreakpoints({});

const enzuzoTheme = (color: any, mode: any, direction: any) => ({
  direction,
  palette: {
    type: mode,
    primary: themePalette(color, mode).palette.primary,
    secondary: themePalette(color, mode).palette.secondary,
    error: themePalette(color, mode).palette.error,
    warning: themePalette(color, mode).palette.warning,
    info: themePalette(color, mode).palette.info,
    success: themePalette(color, mode).palette.success,
    neutral: themePalette(color, mode).palette.neutral,
    grey: themePalette(color, mode).palette.grey,
    action: {
      hover: mode === 'dark' ? 'rgba(80,80,80, 0.9)' : 'rgba(80,80,80, 0.05)',
      hoverOpacity: 0.05
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif',
    ].join(','),
    title: {
      fontWeight: 600
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1.1rem',
    },
    h1: {
      fontWeight: 600,
      fontSize: 35
    },
    h2: {
      fontWeight: 600,
      fontSize: 30
    },
    h3: {
      fontWeight: 600,
      fontSize: 25
    },
    h4: {
      fontWeight: 600,
      fontSize: 20
    },
    body1: {
      fontWeight: 400,
      fontSize: 16
    },
    body2: {
      fontWeight: 400,
      fontSize: 12
    },
    body3: {
      color: `${themePalette(color, mode).palette.primary.main}`,
    },
    h5: {
      fontWeight: 600,
    },
    fontWeightMedium: 600
  },
  shade: {
    light: '0 10px 15px -5px rgba(62, 57, 107, .07)',
  },
  glow: {
    light: `0 2px 20px -5px ${themePalette(color, mode).palette.primary.main}`,
    medium: `0 2px 40px -5px ${themePalette(color, mode).palette.primary.main}`,
    dark: `0 2px 40px 0px ${themePalette(color, mode).palette.primary.main}`,
  },
  rounded: {
    small: '8px',
    medium: '12px',
    big: '20px'
  },
  shadows: mode === 'dark'
    ? [
      'none',
      '0px 1px 3px 0px rgba(50,50,50, 0.2),0px 1px 1px 0px rgba(50,50,50, 0.14),0px 2px 1px -1px rgba(50,50,50, 0.12)',
      '0px 1px 5px 0px rgba(50,50,50, 0.2),0px 2px 2px 0px rgba(50,50,50, 0.14),0px 3px 1px -2px rgba(50,50,50, 0.12)',
      '0px 1px 8px 0px rgba(50,50,50, 0.2),0px 3px 4px 0px rgba(50,50,50, 0.14),0px 3px 3px -2px rgba(50,50,50, 0.12)',
      '0px 2px 4px -1px rgba(50,50,50, 0.2),0px 4px 5px 0px rgba(50,50,50, 0.14),0px 1px 10px 0px rgba(50,50,50, 0.12)',
      '0px 3px 5px -1px rgba(50,50,50, 0.2),0px 5px 8px 0px rgba(50,50,50, 0.14),0px 1px 14px 0px rgba(50,50,50, 0.12)',
      '0px 3px 5px -1px rgba(50,50,50, 0.2),0px 6px 10px 0px rgba(50,50,50, 0.14),0px 1px 18px 0px rgba(50,50,50, 0.12)',
      '0px 4px 5px -2px rgba(50,50,50, 0.2),0px 7px 10px 1px rgba(50,50,50, 0.14),0px 2px 16px 1px rgba(50,50,50, 0.12)',
      '0px 5px 5px -3px rgba(50,50,50, 0.2),0px 8px 10px 1px rgba(50,50,50, 0.14),0px 3px 14px 2px rgba(50,50,50, 0.12)',
      '0px 5px 6px -3px rgba(50,50,50, 0.2),0px 9px 12px 1px rgba(50,50,50, 0.14),0px 3px 16px 2px rgba(50,50,50, 0.12)',
      '0px 6px 6px -3px rgba(50,50,50, 0.2),0px 10px 14px 1px rgba(50,50,50, 0.14),0px 4px 18px 3px rgba(50,50,50, 0.12)',
      '0px 6px 7px -4px rgba(50,50,50, 0.2),0px 11px 15px 1px rgba(50,50,50, 0.14),0px 4px 20px 3px rgba(50,50,50, 0.12)',
      '0px 7px 8px -4px rgba(50,50,50, 0.2),0px 12px 17px 2px rgba(50,50,50, 0.14),0px 5px 22px 4px rgba(50,50,50, 0.12)',
      '0px 7px 8px -4px rgba(50,50,50, 0.2),0px 13px 19px 2px rgba(50,50,50, 0.14),0px 5px 24px 4px rgba(50,50,50, 0.12)',
      '0px 7px 9px -4px rgba(50,50,50, 0.2),0px 14px 21px 2px rgba(50,50,50, 0.14),0px 5px 26px 4px rgba(50,50,50, 0.12)',
      '0px 8px 9px -5px rgba(50,50,50, 0.2),0px 15px 22px 2px rgba(50,50,50, 0.14),0px 6px 28px 5px rgba(50,50,50, 0.12)',
      '0px 8px 10px -5px rgba(50,50,50, 0.2),0px 16px 24px 2px rgba(50,50,50, 0.14),0px 6px 30px 5px rgba(50,50,50, 0.12)',
      '0px 8px 11px -5px rgba(50,50,50, 0.2),0px 17px 26px 2px rgba(50,50,50, 0.14),0px 6px 32px 5px rgba(50,50,50, 0.12)',
      '0px 9px 11px -5px rgba(50,50,50, 0.2),0px 18px 28px 2px rgba(50,50,50, 0.14),0px 7px 34px 6px rgba(50,50,50, 0.12)',
      '0px 9px 12px -6px rgba(50,50,50, 0.2),0px 19px 29px 2px rgba(50,50,50, 0.14),0px 7px 36px 6px rgba(50,50,50, 0.12)',
      '0px 10px 13px -6px rgba(50,50,50, 0.2),0px 20px 31px 3px rgba(50,50,50, 0.14),0px 8px 38px 7px rgba(50,50,50, 0.12)',
      '0px 10px 13px -6px rgba(50,50,50, 0.2),0px 21px 33px 3px rgba(50,50,50, 0.14),0px 8px 40px 7px rgba(50,50,50, 0.12)',
      '0px 10px 14px -6px rgba(50,50,50, 0.2),0px 22px 35px 3px rgba(50,50,50, 0.14),0px 8px 42px 7px rgba(50,50,50, 0.12)',
      '0px 11px 14px -7px rgba(50,50,50, 0.2),0px 23px 36px 3px rgba(50,50,50, 0.14),0px 9px 44px 8px rgba(50,50,50, 0.12)',
      '0px 11px 15px -7px rgba(50,50,50, 0.2),0px 24px 38px 3px rgba(850,50,50 0.14),0px 9px 46px 8px rgba(50,50,50, 0.12)',
    ]
    : [
      'none',
      '0px 1px 3px 0px rgba(80,80,80, 0.2),0px 1px 1px 0px rgba(80,80,80, 0.14),0px 2px 1px -1px rgba(80,80,80, 0.12)',
      '0px 1px 5px 0px rgba(80,80,80, 0.2),0px 2px 2px 0px rgba(80,80,80, 0.14),0px 3px 1px -2px rgba(80,80,80, 0.12)',
      '0px 1px 8px 0px rgba(80,80,80, 0.2),0px 3px 4px 0px rgba(80,80,80, 0.14),0px 3px 3px -2px rgba(80,80,80, 0.12)',
      '0px 2px 4px -1px rgba(80,80,80, 0.2),0px 4px 5px 0px rgba(80,80,80, 0.14),0px 1px 10px 0px rgba(80,80,80, 0.12)',
      '0px 3px 5px -1px rgba(80,80,80, 0.2),0px 5px 8px 0px rgba(80,80,80, 0.14),0px 1px 14px 0px rgba(80,80,80, 0.12)',
      '0px 3px 5px -1px rgba(80,80,80, 0.2),0px 6px 10px 0px rgba(80,80,80, 0.14),0px 1px 18px 0px rgba(80,80,80, 0.12)',
      '0px 4px 5px -2px rgba(80,80,80, 0.2),0px 7px 10px 1px rgba(80,80,80, 0.14),0px 2px 16px 1px rgba(80,80,80, 0.12)',
      '0px 5px 5px -3px rgba(80,80,80, 0.2),0px 8px 10px 1px rgba(80,80,80, 0.14),0px 3px 14px 2px rgba(80,80,80, 0.12)',
      '0px 5px 6px -3px rgba(80,80,80, 0.2),0px 9px 12px 1px rgba(80,80,80, 0.14),0px 3px 16px 2px rgba(80,80,80, 0.12)',
      '0px 6px 6px -3px rgba(80,80,80, 0.2),0px 10px 14px 1px rgba(80,80,80, 0.14),0px 4px 18px 3px rgba(80,80,80, 0.12)',
      '0px 6px 7px -4px rgba(80,80,80, 0.2),0px 11px 15px 1px rgba(80,80,80, 0.14),0px 4px 20px 3px rgba(80,80,80, 0.12)',
      '0px 7px 8px -4px rgba(80,80,80, 0.2),0px 12px 17px 2px rgba(80,80,80, 0.14),0px 5px 22px 4px rgba(80,80,80, 0.12)',
      '0px 7px 8px -4px rgba(80,80,80, 0.2),0px 13px 19px 2px rgba(80,80,80, 0.14),0px 5px 24px 4px rgba(80,80,80, 0.12)',
      '0px 7px 9px -4px rgba(80,80,80, 0.2),0px 14px 21px 2px rgba(80,80,80, 0.14),0px 5px 26px 4px rgba(80,80,80, 0.12)',
      '0px 8px 9px -5px rgba(80,80,80, 0.2),0px 15px 22px 2px rgba(80,80,80, 0.14),0px 6px 28px 5px rgba(80,80,80, 0.12)',
      '0px 8px 10px -5px rgba(80,80,80, 0.2),0px 16px 24px 2px rgba(80,80,80, 0.14),0px 6px 30px 5px rgba(80,80,80, 0.12)',
      '0px 8px 11px -5px rgba(80,80,80, 0.2),0px 17px 26px 2px rgba(80,80,80, 0.14),0px 6px 32px 5px rgba(80,80,80, 0.12)',
      '0px 9px 11px -5px rgba(80,80,80, 0.2),0px 18px 28px 2px rgba(80,80,80, 0.14),0px 7px 34px 6px rgba(80,80,80, 0.12)',
      '0px 9px 12px -6px rgba(80,80,80, 0.2),0px 19px 29px 2px rgba(80,80,80, 0.14),0px 7px 36px 6px rgba(80,80,80, 0.12)',
      '0px 10px 13px -6px rgba(80,80,80, 0.2),0px 20px 31px 3px rgba(80,80,80, 0.14),0px 8px 38px 7px rgba(80,80,80, 0.12)',
      '0px 10px 13px -6px rgba(80,80,80, 0.2),0px 21px 33px 3px rgba(80,80,80, 0.14),0px 8px 40px 7px rgba(80,80,80, 0.12)',
      '0px 10px 14px -6px rgba(80,80,80, 0.2),0px 22px 35px 3px rgba(80,80,80, 0.14),0px 8px 42px 7px rgba(80,80,80, 0.12)',
      '0px 11px 14px -7px rgba(80,80,80, 0.2),0px 23px 36px 3px rgba(80,80,80, 0.14),0px 9px 44px 8px rgba(80,80,80, 0.12)',
      '0px 11px 15px -7px rgba(80,80,80, 0.2),0px 24px 38px 3px rgba(80,80,80, 0.14),0px 9px 46px 8px rgba(80,80,80, 0.12)',
    ],
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor:
          mode === 'dark'
            ? '#292929'
            : '#FFFFFF'
      },
      rounded: {
        borderRadius: 3,
      },
      elevation1: {
        boxShadow:
          mode === 'dark'
            ? '0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)'
            : '0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
      },
      elevation4: {
        boxShadow: 'none',
        border: `solid 1px ${themePalette(color, mode).palette.grey.light}`,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        borderRadius: 5,
        fontSize: 14,
      },
      containedPrimary: {
        backgroundColor: themePalette(color, mode).palette.primary.main,
        color: themePalette(color, mode).palette.neutral.sand,
        '&:hover': {
          backgroundColor: themePalette(color, mode).palette.primary.dark,
          boxShadow: 'none'
          // boxShadow: `0px 0px 5px 0px ${themePalette(color, mode).palette.primary.dark}`,
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
        '&:active': {
          boxShadow: `0px 0px 5px 0px ${themePalette(color, mode).palette.primary.main}`,
          // boxShadow: 'none'
        }
      },
      root: {
        borderRadius: 3,
        fontWeight: 600,
        textTransform: 'none',
        padding: '10px 30px'
      },
      sizeSmall: {
        padding: '7px 12px',
      },
      sizeLarge: {
        padding: '20px 30px',
        fontSize: 16,
      },
    },
    MuiIconButton: {
      // root: {
      //   '&:hover': {
      //     backgroundColor: 'rgba(142,177,218,0.85)',
      //   },
      // },
      colorPrimary: {
        color: themePalette(color, mode).palette.neutral.sand,
        '&:hover': {
          backgroundColor: themePalette(color, mode).palette.primary.light,
        },
      },
    },
    MuiTypography: {
      button: {
        fontWeight: 600,
      },
      subtitle1: {
        marginBottom: '1em',
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'white',
      },
      input: {
        flex: 1,
      },
    },
    MuiInput: {
      root: {
        overflow: 'hidden',
        borderBottom: '1px solid rgba(216,224,236,32)',
        alignItems: 'center',
        transition: 'border 0.3s ease',
        width: '100%',
      },
      underline: {
        '&:after': {
          height: '1px',
          borderRadius: 4,
          bottom: -1,
          boxShadow: 'none',
          borderBottomColor: themePalette(color, mode).palette.grey.dark,
        },
        '&:before': {
          display: 'none'
        },
        '&.Mui-focused': {
          '&:after': {
            borderBottomColor: themePalette(color, mode).palette.grey.med,
          },
        },
      },
      input: {
        padding: '14px 10px 10px 10px',
        fontSize: 14,
      },
      multiline: {
        paddingTop: 4,
      },
    },
    // material UI input label placement/animation
    MuiInputLabel: {
      formControl: {
        top: 6,
        left: 10,
        transform: 'translate(0, 22px) scale(1)',
        background: '#FFF',
        zIndex: 1,
      },
      shrink: {
        transform: 'translate(0, 6px) scale(0.7)',
        zIndex: 1,
        padding: '0 2px',
      },
      filled: {
        transform: 'translate(2px, 8px) scale(1)',
        '&$shrink': {
          transform: 'translate(0px, -6px) scale(0.75)',
        },
      },
      outlined: {
        transform: 'translate(2px, 12px) scale(1)',
        zIndex: 11,
        '&$shrink': {
          transform: 'translate(4px, -12px) scale(1)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: themePalette(color, mode).palette.grey.offWhite,
            borderColor: themePalette(color, mode).palette.grey.light,
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: themePalette(color, mode).palette.grey.mid,
            backgroundColor: themePalette(color, mode).palette.grey.offWhite,
            borderWidth: 1,
          },
        },
      },
      input: {
        padding: '16px 14px',
        position: 'relative',
        zIndex: 10,
      },
      notchedOutline: {
        borderWidth: 1,
        zIndex: 1,
        borderColor: themePalette(color, mode).palette.grey.light,
      },
    },
    MuiFormLabel: {
      root: {
        transform: 'uppercase',
        marginTop: 8,
        marginBottom: 8,
        color: themePalette(color, mode).palette.grey.text,
        '&.Mui-focused': {
          color: themePalette(color, mode).palette.grey.dark,
        }
      },
    },
    MuiFormHelperText: {
      root: {
        paddingLeft: 0,
      },
    },
    MuiSelect: {
      root: {
        borderRadius: 8,
      },
      icon: {
        top: 'calc(50% - 10px)',
        right: 0,
        zIndex: 5,
      },
      select: {
        '&:focus': {
          backgroundColor: themePalette(color, mode).palette.info.extraLight,
        },
      },
      selectMenu: {
        paddingRight: '24px',
      }
    },
    MuiFormControl: {
      root: {
        width: '100%',
        '& .MuiFormLabel-root.MuiInputLabel-root': {
          margin: '0px',
        },
      },
      marginDense: {
        marginTop: 2,
        marginBottom: 4,
      },
    },
    MuiInputAdornment: {
      root: {
        alignItems: 'flex-end',
        paddingLeft: 0,
        paddingRight: 0,
        height: 'auto',
        '& button': {
          width: 32,
          height: 32,
          padding: 0,
        },
        '& p': {
          minWidth: 24,
          lineHeight: '16px'
        },
        '& > svg': {
          position: 'relative',
          top: 3,
        }
      },
      positionStart: {
        marginLeft: 0,
      },
      positionEnd: {
        marginRight: 0,
      },
    },
    MuiToolbar: {
      root: {
        borderRadius: 8,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        // fontWeight: '500',
        width: '200px'
      }
    },
    MuiTableCell: {
      root: {
        padding: 8,
        borderBottom:
          mode === 'dark'
            ? '1px solid #636363'
            : `1px solid ${themePalette(color, mode).palette.neutral.sand}`,
        fontWeight: 400,
      },
      head: {
        fontWeight: 600,
      },
    },
    MuiListItemText: {
      root: {
        whiteSpace: 'nowrap'
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 16,
        height: 8,
      },
      bar: {
        borderRadius: 16,
      },
      colorPrimary: {
        backgroundColor: mode === 'dark' ? '#616161' : '#9CA3AF',
      },
    },
    MUIDataTableBodyCell: {
      stackedCommon: {
        [breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    MuiTablePagination: {
      actions: {
        marginRight: 64,
      },
      input: {
        marginRight: 32,
        marginLeft: 8,
      },
      selectRoot: {
        marginLeft: 0,
        marginRight: 0,
      },
      select: {
        paddingRight: 24,
      },
      selectIcon: {
        top: 4,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        borderRadius: 0,
        boxShadow: 'inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)',
      },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: 'none',
        border: `1px solid ${themePalette(color, mode).palette.primary.main}`,
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        boxShadow: `0 1px 10px 0px ${themePalette(color, mode).palette.primary.main}`,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: 'transparent',
        color: themePalette(color, mode).palette.primary.main,
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:first-child': {
          borderTopLeftRadius: 1,
          borderTopRightRadius: 1,
        },
        '&:last-child': {
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
        },
        '&$expanded': {
          borderRadius: 1,
          '& + div': {
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
          },
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 24,
        margin: 4,
        '&$expanded': {
          margin: 4,
          minHeight: 24,
        },
      },
      content: {
        margin: 4,
        '&$expanded': {
          margin: 4,
        },
      },
      expandIcon: {
        padding: 4,
      },
    },
    MuiDialogTitle: {
      root: {
        position: 'relative',
        marginBottom: 4,
        padding: '8px 24px 8px 24px',
        '& h2': {
          color:
            mode === 'dark'
              ? themePalette(color, mode).palette.primary.light
              : themePalette(color, mode).palette.primary.dark,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        '@media (min-width: 960px)': {
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        backgroundColor:
          mode === 'dark'
            ? themePalette(color, mode).palette.primary.dark
            : themePalette(color, mode).palette.primary.main,
      },
    },
    MuiTabs: {
      root: {
        borderRadius: 10,
      },
      indicator: {
        borderRadius: '10px 10px 0 0',
        height: 4,
      },
    },
    MuiToggleButtonGroup: {
      root: {
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: 'none',
        border: `1px solid ${themePalette(color, mode).palette.secondary.main}`,
      },
    },
    MuiToggleButton: {
      root: {
        height: 32,
        boxShadow: 'none !important',
        '&$selected': {
          color: themePalette(color, mode).palette.secondary.main,
          backgroundColor: themePalette(color, mode).palette.secondary.light
        }
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        boxShadow: 'none',
        backgroundColor:
          mode === 'dark'
            ? themePalette(color, mode).palette.secondary.dark
            : themePalette(color, mode).palette.secondary.light,
      },
      title: {
        padding: direction === 'rtl' ? '0 26px 0 0' : '0 0 0 26px',
      },
      deleteIcon: {
        color: mode === 'dark' ? '#FFF' : '#000',
      },
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        '&.chip': {
          border: `1px solid ${themePalette(color, mode).palette.grey.med}`,
          color: themePalette(color, mode).palette.grey.med,
          height: 'auto',
          padding: '0.15rem',
          fontSize: 13,
          fontWeight: 400,
          marginLeft: '2rem',
          backgroundColor: 'transparent',
        },
        '&.chip_planPremium': {
          border: '1px solid #E2776A',
          color: '#E2776A',
          backgroundColor: 'transparent',
        },
      },
      deleteIcon: {
        margin: direction === 'rtl' ? '0 -8px 0 4px' : '0 4px 0 -8px'
      }
    },
    MuiSwitch: {
      root: {
        direction: 'ltr'
      },
      switchBase: {
        '&.MuiSwitch-colorSecondary.MuiIconButton-root': {
          '&:hover': {
            backgroundColor: 'rgba(250,144,56,0.55)',
          },
        },
      }
    },
    MuiAlert: {
      root: {
        position: 'relative',
        borderRadius: 3,
      },
      icon: {
        opacity: 1,
      },
      message: {
        '& p:last-child': {
          marginBottom: 0
        },
      },
      standardWarning: {
        border: `solid 1px ${themePalette(color, mode).palette.warning.main}`,
        '& .MuiAlert-icon': {
          color: themePalette(color, mode).palette.warning.main,
        },
      },
      standardInfo: {
        border: `solid 1px ${themePalette(color, mode).palette.info.main}`,
        '& .MuiAlert-icon': {
          color: themePalette(color, mode).palette.info.main,
        },
      },
      standardSuccess: {
        border: `solid 1px ${themePalette(color, mode).palette.success.dark}`,
        '& .MuiAlert-icon': {
          color: themePalette(color, mode).palette.success.dark,
        },
      },
      standardError: {
        border: `solid 1px ${themePalette(color, mode).palette.error.dark}`,
        '& .MuiAlert-icon': {
          color: themePalette(color, mode).palette.error.main,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: themePalette(color, mode).palette.primary.main,
        '& span': {
          '& svg': {
            '& path': {
              d: 'path(\'M 16 19.5 H 8 C 6 19.5 4.5 18 4.5 16 V 8 C 4.5 6 6 4.5 8 4.5 H 16 C 18 4.5 19.5 6 19.5 8 V 16 C 19.5 18 18 19.5 16 19.5 Z M 19 3 H 8 C 5 3 3 5 3 8 V 16 C 3 19 5 21 8 21 H 16 C 19 21 21 19 21 16 V 8 C 21 5 19 3 16 3 Z\')'
            }
          }
        },
        '&$checked': {
          color: `${themePalette(color, mode).palette.primary.main}`,
          '& span': {
            '& svg': {
              '& path': {
                d: 'path(\'M 19 3 H 8 C 5 3 3 5 3 8 V 16 C 3 19 5 21 8 21 H 16 C 19 21 21 19 21 16 V 8 C 21 6 19 3 16 3 Z M 10 17 L 5 12 L 6 11 L 10 15 L 18 7 L 19 8 L 10 17\')'
              }
            }
          }
        },
        '&:hover': {
          backgroundColor: 'rgba(12, 58, 68, 0.05)',
        },
        '&$disabled': {
          color: `${themePalette(color, mode).palette.grey.main}`
        }
      },
      colorSecondary: {
        '&$checked': {
          color: `${themePalette(color, mode).palette.primary.main}`,
        }
      }
    },
    MuiRadio: {
      root: {
        '& span': {
          '& svg': {
            '& path': {
              d: 'path(\'M 12 2 C 6.48 2 2 6.48 2 12 S 6.48 22 12 22 S 22 17.52 22 12 S 17.52 2 12 2 Z M 12 20.5 C 7.5 20.5 3.5 16.5 3.5 12 S 7.5 3.5 12 3.5 S 20.5 7.5 20.5 12 S 16.5 20.5 12 20.5 Z\')'
            }
          }
        },
        color: `${themePalette(color, mode).palette.primary.main}`,
        '&$checked': {
          color: `${themePalette(color, mode).palette.primary.main}`,
          '& span': {
            '& svg': {
              '& path': {
                d: 'path(\'M 12 2 C 6.48 2 2 6.48 2 12 S 6.48 22 12 22 S 22 17.52 22 12 S 17.52 2 12 2 Z M 12 14.5 C 10.5 14.5 9.5 13.5 9.5 12 S 10.5 9.5 12 9.5 S 14.5 10.5 14.5 12 S 13.5 14.5 12 14.5 Z\')'
              }
            }
          }
        },
      },
      colorPrimary: {
        // '&.Mui-checked': {
        // },
        '&:hover': {
          backgroundColor: 'rgba(12, 58, 68, 0.05)',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${themePalette(color, mode).palette.primary.main}`,
        }
      }
    },
  },
});

export default enzuzoTheme;

import { Region } from "./Region";

export enum PPAType {
    CUSTOM = '',
    NOT_FOUND = 'not found',
    ENZUZO = 'enzuzo',
    ENZUZO_COPY_PASTE = 'enzuzo_copy_paste',
    SHOPIFY_COMPLETED = 'shopify completed',
    SHOPIFY_DEFAULT = 'shopify default',
    UNABLE_TO_SPECIFY = 'unable to specify'
}

export enum CBAType {
    ENZUZO = 'enzuzo',
    OSANO = 'osano',
    TRUSTARC = 'trustarc',
    ONETRUST = 'onetrust',
    COOKIEBOT = 'cookiebot',
    IUBENDA = 'iubenda',
    DIDOMI = 'didomi',
    CIVIC = 'civic',
    NOT_FOUND_OR_VENDOR_NOT_RECOGNIZED = 'not found or vendor not recognized',
    UNABLE_TO_SPECIFY = 'unable to specify'
}

export enum AppType {
    SHOPIFY = 'shopify',
    SHOPIFY_COMPLETED = 'shopify completed',
    SHOPIFY_DEFAULT = 'shopify default',
    SHOPIFY_PASSWORD_PROTECTED = 'shopify password protected',
    ENZUZO = 'enzuzo',
    ENZUZO_COPY_PASTE = 'enzuzo_copy_paste',
    CUSTOM = 'custom',
    NOT_FOUND = 'not found',
    UNABLE_TO_SPECIFY = 'unable to specify'
}

export enum PrivacyTerms {
    GDPRTerm = 'gdpr-term',
    CCPATerm = 'ccpa-term',
    CPRATerm = 'cpra-term',
    PIPEDATerm = 'pipeda-term',
    LGPDTerm = 'lgpd-term',
    CPATerm = 'cpa-term',
    VCDPATerm = 'vcdpa-term',
    CTDPATerm = 'ctdpa-term',
    UCPATerm = 'ucpa-term',
    POPIATerm = 'popia-term'
}

type ComplianceHealthScore = {
    regions: Region[],
    score: number,
    legal_policy_score: number,
    cookie_consent_score: number,

    privacy_policy: {
        score: number,
        type: PPAType,
        terms: PrivacyTerms[],
        expected_terms: PrivacyTerms[],
        is_required: boolean
    },
    terms_of_service: {
        score: number,
        type: AppType,
        is_required: boolean
    },
    cookie_banner: {
        score: number,
        type: CBAType,
        is_required: boolean
    },
    cookie_manager: {
        score: number,
        type: AppType,
        is_required: boolean
    }
};

export default ComplianceHealthScore;
